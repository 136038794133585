import React, { Component } from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import PropTypes from 'prop-types'

class Checkbox extends Component {
  //LIFECYCLE
  constructor(props){
    super(props)
    this.state = {
      checked: props.checked
    }
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.checked !== this.props.checked){
      this.setState({checked: this.props.checked})
    }
  }

  //EVENTS
  changed = () => {
    this.setState(
      (prevState) => { prevState.checked = !prevState.checked }, 
      () => {this.props.toggled(this.props.name, this.state.checked)}
    )
  }

  //RENDER
  render() {
    const labelId = 'label_' + this.props.name.replace(':', '_')

    return (
      <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', cursor: 'pointer'}}>
        <input name={this.props.name} type='checkbox' defaultChecked={this.state.checked} onChange={this.changed} className='temp-checkbox' disabled={this.props.disabled}/>
        <p id={labelId} style={{display: 'inline-block', textDecoration: 'underline', color: 'blue'}}>
          {this.props.label}
        </p>
        <UncontrolledTooltip target={labelId} placement={this.props.tooltipPlacement || 'right'}>{this.props.tooltip}</UncontrolledTooltip>
      </div>
    )
  }
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
}

export default Checkbox