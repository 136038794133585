const initialState = {
    message: null,
    type: null
}

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'notification/SHOW':
            return {
                ...state,
                message: action.payload.message,
                type: action.payload.type
            }
        case '*/CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
}

export default modalReducer;