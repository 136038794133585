import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { BaseButton, EventForm } from '../components'
import * as actions from '../actions'
import ApiHelper from '../utilities/apiHelper'
import { Endpoints } from '../Constants'
import { urls } from '../pages'
import Helpers from '../utilities/helpers'

class Events extends Component{
  constructor(props){
    super(props)
    this.state = {
      selectedEvent: null,
      events: []
    }
  }
  componentDidMount = async () => {
    if (ApiHelper.isAuthExpired()){
      let refreshed = await ApiHelper.tryRefresh()
      if (!refreshed){
        this.props.history.push(urls.login)
        return
      }
    }
    this.loadData()
  }

  //METHODS
  loadData = async () => {
    this.props.showLoading()
    let response = await ApiHelper.get(Endpoints.Events)
    this.props.closeLoading()

    if (!response.success){
      let message
      switch(response.status){
        default:
          message = 'There was an issue while retrieving the list of events.'
      }
      this.props.alert('error', message)
      return
    }

    this.setState({ events: response.data, selectedEvent: null })
  }
  openForm = () => {
    this.setState({ selectedEvent: {} })
  }
  eventClicked = (selectedEvent) => {
    this.setState({ selectedEvent })
  }
  createEvent = async (e) => {
    this.props.showLoading()

    let { selectedEvent } = this.state
    let response = await ApiHelper.put(Endpoints.Events, selectedEvent)
    this.props.closeLoading()

    if (!response.success){
      let message
      switch(response.status){
        default:
          message = 'There was an issue while creating the event.'
      }
      this.props.alert('error', message)
      return
    }

    this.loadData()
    this.props.alert('success', 'The event has been saved' + (this.state.selectedEvent.pushnotify || this.state.selectedEvent.emailnotify ? ' and published.' : ' but not published.'))
  }
  updateEvent = async (e) => {
    this.props.showLoading()
    let response = await ApiHelper.post(Endpoints.Events, this.state.selectedEvent)
    this.props.closeLoading()

    if (!response.success){
      let message
      switch(response.status){
        default:
          message = 'There was an issue while updating the event.'
      }
      this.props.alert('error', message)
      return
    }
    this.loadData()
    this.props.alert('success', 'The event has been updated.')
  }
  deleteEvent = async (e) => {
    this.props.showLoading()
    let response = await ApiHelper.delete(Endpoints.Events + '?idevent=' + this.state.selectedEvent.idevent)
    this.props.closeLoading()

    if (!response.success){
      let message
      switch(response.status){
        default:
          message = 'There was an issue while deleting the event.'
      }
      this.props.alert('error', message)
      return
    }
    this.loadData()
    this.props.alert('info', 'The event has been deleted.')
  }
  unselectEvent = (e) => {
    this.setState({selectedEvent: null})
  }
  getDateString = (event) => {
    if (event.recurring) {
      return event.recurringtext
    }
    
    let str = Helpers.FormatShortDate(event.startdate)

    if (event.enddate) {
      str += ' - ' + Helpers.FormatShortDate(event.enddate)
    }

    return str
  }

  //EVENT HANDLERS
  handleUpdates = (name, value) => {
    this.setState((prevState) => {
      if (name.indexOf(':') > 0){
        let parts = name.split(':')
        let objName = parts[0]
        let keyName = parts[1]

        prevState[objName][keyName] = value
        return { [objName]: prevState[objName] }
      } else {
        prevState[name] = value
        return { prevState }
      }
    })
  }
  cbChanged = (name, val) => {
    this.setState((prevState) => {
      prevState.user[name] = val
    })
  }

  //RENDER
  render() {
    return (
      <div className='content-view'>
        <div className='centered-container'>
          <div className='content-card singular-card' style={{minWidth: '800px'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <p className='page-header'>Events</p>
              <BaseButton content='Create Event' classes='add-button' onClick={this.openForm}/>
            </div>
            {
              this.state.events.length > 0 ? (
                <div>
                  <p className='italic-text'>Select an event below to view or change details</p>
                  <table>
                    <thead>
                      <tr>
                        <th style={{minWidth: '150px'}}>Title</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.events.map((item, i) => (
                        <tr key={'e' + item.idevent} onClick={() => this.eventClicked(item)}>
                          <td>{item.title}</td>
                          <td>{this.getDateString(item)}</td>
                          <td>{item.time}</td>
                          <td>{item.description}</td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className='italic-text'>There are currently no upcoming events.</p>
              )
            }

            <EventForm
              event={this.state.selectedEvent}
              save={this.createEvent}
              update={this.updateEvent}
              delete={this.deleteEvent}
              cancel={this.unselectEvent}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.user.session,
    }
  }
const mapDispatchToProps = (dispatch) => {
  return {
    alert: (type, message) => dispatch(actions.alert(type, message)),
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading())
  }
}

Events.propTypes = { 
  user: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Events)