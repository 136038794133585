//MODAL
export const showLoading = () => {
  return { type: "modal/SHOW_LOADING", payload: true }
}
export const closeLoading = () => {
  return { type: "modal/CLOSE_LOADING", payload: false }
}

//NOTIFICATIONS
export const alert = (type, message) => {
  return { type: "notification/SHOW", payload: {type, message}}
}

//USERS
export const setAuthToken = (str) => {
  return { type: "user/SET_AUTH", payload: str }
}
export const setRefreshToken = (str) => {
  return { type: "user/SET_REFRESH", payload: str }
}
export const setUserSession = (obj) => {
  return { type: "user/SET_SESSION", payload: obj }
}
export const setCognitoUser = (obj) => {
  return { type: "user/SET_COGNITO_USER", payload: obj }
}
export const setUserList = (list) => {
  return { type: "user/SET_LIST", payload: list }
}

//ALL
export const logout = () => {
  return { type: "*/CLEAR_STORE", payload: null }
}