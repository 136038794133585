import { combineReducers } from 'redux'
import modalReducer from './modalReducer'
import notificationReducer from './notificationReducer'
import userReducer from './userReducer'

const rootReducer = combineReducers({
    modal: modalReducer,
    notifications: notificationReducer,
    user: userReducer,
})

export default rootReducer