const initialState = {
    cognitoUser: {},
    session: {},
    list: []
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'user/SET_AUTH':
            return {
                ...state,
                authtoken: action.payload 
            }
        case 'user/SET_REFRESH':
            return {
                ...state,
                refreshtoken: action.payload 
            }
        case 'user/SET_SESSION':
            return {
                ...state,
                session: action.payload
            }
        case 'user/SET_COGNITO_USER':
            return {
                ...state,
                cognitoUser: action.payload
            }
        case 'user/SET_LIST':
            return {
                ...state,
                list: action.payload
            }
        case '*/CLEAR_STORE':
            return initialState
        default:
            return state
    }
}

export default userReducer