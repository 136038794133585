const baseUrl = process.env.REACT_APP_SERVER_URL;

export const Endpoints = {
    AppSignUp: baseUrl + '/users/presignup',
    ChangePw: baseUrl + '/user/change-pw',
    Events: baseUrl + '/events',
    ForgotPassword: baseUrl + '/user/forgot-password',
    ForgotPasswordConfirm: baseUrl + '/user/forgot-password-confirm',
    Listings: baseUrl + '/listings',
    Menu: baseUrl + '/menu',
    MenuCache: baseUrl + '/menu/cache',
    MenuCategories: baseUrl + '/menu/categories',
    MenuItems: baseUrl + '/menu/items',
    PreSignUpload: baseUrl + '/menu/upload',
    Profile: baseUrl + '/profile',
    RefreshAuthToken: baseUrl + '/user/refresh',
    ResetUserPw: baseUrl + '/user/reset-pw',
    RewardCode: baseUrl + '/reward/code',
    Rewards: baseUrl + '/rewards',
    Settings: baseUrl + '/settings',
    Users: baseUrl + '/users',
    UserPoints: baseUrl + '/users/points',
    WineSurveys: baseUrl + '/users/surveys'
}
export const Days = [
    { name: 'Sunday' }, 
    { name: 'Monday' }, 
    { name: 'Tuesday' }, 
    { name: 'Wednesday' }, 
    { name: 'Thursday' }, 
    { name: 'Friday' }, 
    { name: 'Saturday' }
]