import React, { Component } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ConditionalDOM } from './';
import Helpers from '../utilities/helpers';

class MultiSelectDropDown extends Component {
  constructor(props){
    super(props);
    let list = [];

    for(let item of props.items){
      list.push(item[props.titleProp]);
    }

    this.state = {
      dropList: list,
      isOpen: false,
      items: props.items,
      placeholder: props.placeholder,
      selections: []
    };
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.placeholder !== this.props.placeholder){
      this.setState({placeholder: this.props.placeholder});
    }
  }

  //METHODS
  selectionChanged = (index) => {
    let item = this.props.items[index];

    this.setState({placeholder: item[this.props.titleProp]});
    this.props.selectionChanged(item);
  }
  toggle = () => {
    this.setState({isOpen: !this.state.isOpen});
  }
  selected = (displayValue) => {
    this.setState((prevState) => {
      if (prevState.selections.indexOf(displayValue) >= 0){
        prevState.selections = prevState.selections.filter(el => el !== displayValue);
      } else {
        prevState.selections.push(displayValue);
      }

      let n = prevState.selections.length;

      this.props.listUpdated(prevState.selections);
      return {selections: prevState.selections, placeholder: Helpers.Pluralize('user', n, true) + ' selected'}
    });
  }
  checkChecked = (displayValue) => {
    for(let val of this.state.selections){
      if (val === displayValue) {
        return true;
      }
    }
    return false;
  }

  //THE RENDER FUNCTION
  render() {
    return (
      <Dropdown isOpen={this.state.isOpen} toggle={this.toggle} style={{marginRight: '10px'}}>
        <DropdownToggle style={{backgroundColor: '#f8f7ec', border: '1px solid black', borderRadius: '0px', color: 'black'}} caret>{this.state.placeholder}</DropdownToggle>
        <DropdownMenu className='reasonably-sized-menu' style={{width: '250px'}}>
          <ConditionalDOM render={this.state.items}>
          {
            this.state.dropList.map((displayValue, i) => (
              <DropdownItem autoFocus={i === 0} key={'dropdownitem_'+displayValue} style={{textAlign: 'left'}}>
                <div onClick={e => e.stopPropagation()} style={{display: 'flex', flexFlow: 'row wrap'}}>
                  <input type='checkbox' name='selector' checked={this.checkChecked(displayValue)} onChange={(e) => {e.stopPropagation(); this.selected(displayValue)}} className='temp-checkbox'/>
                  <p style={{padding: '0px', margin: '0px'}}>{displayValue}</p>
                </div>
              </DropdownItem>
            ))
          }
          </ConditionalDOM>
          <ConditionalDOM render={!this.state.items}>
            <DropdownItem disabled>{this.props.emptyText}</DropdownItem>
          </ConditionalDOM>
        </DropdownMenu>
      </Dropdown>
    )
  }
}

export default MultiSelectDropDown;
