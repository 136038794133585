import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import * as actions from '../actions';
import { Endpoints } from '../Constants';
import { urls } from '.';
import ApiHelper from '../utilities/apiHelper';
import { BaseInput, ConditionalDOM, UserForm } from '../components';

class ManageUsers extends Component{
  constructor(props){
    super(props);
    this.state = {
      selectedUser: null,
      surveys: [],
      users: null
    };
  }
  componentDidMount = async () => {
    if (ApiHelper.isAuthExpired()){
      let refreshed = await ApiHelper.tryRefresh();
      if (!refreshed){
        this.props.history.push(urls.login);
        return;
      }
    }
    // this.loadData();
    this.props.closeLoading();
  }

  //METHODS
  loadData = async () => {
    if (this.props.users && this.props.users.length > 0){
      this.setState({users: this.props.users});
      this.props.closeLoading();
      return;
    }
    this.props.showLoading();
    let response = await ApiHelper.get(Endpoints.Users);
    if (response.data){
      this.setState({users: response.data})
    }
    this.props.closeLoading();
  }
  userClicked = (selectedUser) => {
    this.setState({ selectedUser });
  }
  closeDetail = () => {
    this.setState({ selectedUser: null });
  }
  resetUserPw = async () => {
    let derpFlag = this.state.selectedUser.iduser === this.props.user.iduser;
    if (derpFlag) {
      this.props.alert('error', 'You may reset your own password from here.\nPlease use the profile page to change it instead.')
      return
    }
    let message = 'Are you sure that you want to reset this user\'s password? This will send them an email with instructions on how to log in again.';

    let confirmed = window.confirm(message);
    if (!confirmed){
      return;
    }
    
    this.props.showLoading();
    let response = await ApiHelper.put(Endpoints.ResetUserPw + '?id=' + this.state.selectedUser.iduser);

    if (response.success){
      this.props.alert('success', 'The user\'s password was reset successfully, and they have been emailed a temporary password.');
      this.closeDetail();
    } else if (response.status === 404) {
      this.props.alert('error', 'The user was not found.');
    } else {
      this.props.alert('error', 'Something went wrong while trying to reset this user\'s password.');
    }
    this.props.closeLoading();
  }
  showAddForm = () => {
    let user = { iduser: 0 };
    this.setState({ selectedUser: user });
  }
  createUser = async (user) => {
    if (!user.firstname || user.firstname.length < 1 || !user.lastname || user.lastname.length < 1){
      this.props.alert('error', 'First and Last name are required.');
      return;
    }
    this.props.showLoading();
    let response = await ApiHelper.put(Endpoints.Users, user);

    if (response.success){
      // this.loadData();
      this.props.alert('success', `Created an account for ${user.firstname}, and emailed them their new password.`);
      this.unselectUser()
    } else if (response && response.status === 409) {
      this.props.alert('info', 'A user with that email already exists.');
    } else {
      this.props.alert('error', 'Something went wrong while trying to create the user.');
    }
    this.props.closeLoading();
  }
  unselectUser = () => {
    this.setState({ selectedUser: null })
  }
  updateUser = async (user) => {
    if (!user.iduser){
      this.props.alert('info', 'Please select a user.');
      return;
    }
    if (!user.firstname || user.firstname.length < 1 || !user.lastname || user.lastname.length < 1){
      this.props.alert('error', 'First and Last name are required.');
      return;
    }

    this.props.showLoading();
    let response = await ApiHelper.post(Endpoints.Users, user);

    if (response.success){
      // this.loadData();
      this.props.alert('success', 'Updated user account.');
    } else {
      this.props.alert('error', 'Something went wrong while trying to update the user account.');
    }
    this.props.closeLoading();
  }
  deleteUser = async (user) => {
    if (user.iduser === this.props.user.iduser) {
      this.props.alert('error', 'You may not delete your own Admin account.')
      return
    }
    let message = !user.authid ? `Are you sure that you want to delete this app pre-signup request? Their email address will be removed from the system.` 
      : `Are you sure that you want to delete this user's account permanently? Effective immediately, they will be unable to access the app${user.webaccess ? ' and admin portal.' : '.'}`;
    let confirmed = window.confirm(message);
    if (!confirmed){
      return;
    }
    if (!user.iduser){
      this.props.alert('info', 'Please select a user.');
      return;
    }

    this.props.showLoading();

    let params = `?id=${user.iduser}`
    let response = await ApiHelper.delete(Endpoints.Users + params);

    if (response.success){
      // this.loadData();
      this.props.alert('success', 'Deleted user account.');
      this.unselectUser()
    } else {
      this.props.alert('error', 'Something went wrong while trying to delete the user account.');
    }
    this.props.closeLoading();
  }
  searchUserClicked = async () => {
    if (!this.state.usersearch){
      this.props.alert('error', 'Please enter a user\'s name or email.')
      return
    }
    this.props.showLoading()
    let response = await ApiHelper.get(Endpoints.Users + `?search=${this.state.usersearch}`)
    this.props.closeLoading()

    if (response.success){
      this.setState({users: response.data})
    } else {
      this.props.alert('error', 'Unable to find any matches.');
    }
  }
  loadWineProfiles = async () => {
    this.props.showLoading()
    let response = await ApiHelper.get(Endpoints.WineSurveys)
    this.props.closeLoading()
    if (!response.success){
      this.props.alert('error', 'Unable to load wine profiles at this time.')
      return
    }

    this.setState({ surveys: response.data })
    this.props.alert('success', 'Loaded the survey data!')
  }


  //EVENT HANDLERS
  handleUpdates = (name, value) => {
    this.setState((prevState) => {
      if (name.indexOf(':') > 0){
        let parts = name.split(':')
        let objName = parts[0]
        let keyName = parts[1]

        prevState[objName][keyName] = value
        return { [objName]: prevState[objName] }
      } else {
        prevState[name] = value
        return { prevState }
      }
    })
  }
  permissionChanged = (e) => {
    let name = e.target.name;
    this.setState((prevState) => {
      let selectedUser = prevState.selectedUser;
      selectedUser[name] = !selectedUser[name];
      return { selectedUser }
    });
  }
  cbChanged = (name, val) => {
    this.setState((prevState) => {
      prevState.selectedUser[name] = val
    })
  }

  //RENDER
  render() {
    return (
      <div className='content-view'>
        <div className='centered-container'>
          <div className='content-card singular-card'>
            {/* MAIN VIEW */}
            <div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <p className='page-header'>Manage Users</p>
                {
                  this.state.users ? (
                    <button onClick={() => this.setState({users: null, usersearch: ''})} className='base-button heading-button'>Clear</button>
                  ) : (
                    <button onClick={this.showAddForm} className='base-button heading-button'>Add</button>
                  )
                }
              </div>
              {
                this.state.users ? (
                  <div>
                    <p className='italic-text'>{'Select a user below to view details'}</p>
                    <table>
                      <thead>
                        <tr className='header-row'>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Admin?</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        this.state.users.map((item, i) => (
                          <tr key={'u'+item.iduser} onClick={() => this.userClicked(item)}>
                            <td>{item.firstname}</td>
                            <td>{item.lastname}</td>
                            <td>{item.email}</td>
                            <td>{item.webaccess ? 'Yes' : 'No'}</td>
                          </tr>
                        ))
                      }
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div>
                    <Row style={{marginBottom: '20px'}}>
                      <Col xs='8'>
                        <BaseInput name='usersearch' changeHandler={this.handleUpdates} label='Search for a User' type='text'/>
                      </Col>
                      <Col xs='4'>
                        <div className='temp-offset-for-input'>
                          <button className='base-button' onClick={this.searchUserClicked}>Search for Account</button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )
              }
            </div>
            <UserForm 
              user={this.state.selectedUser}
              adminEnabled={true}
              save={this.createUser}
              update={this.updateUser}
              delete={this.deleteUser}
              cancel={this.unselectUser}>
            </UserForm>
          </div>
          <div className='content-card singular-card'>
            <div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <p className='page-header'>Wine Profiles</p>
              </div>
              <ConditionalDOM render={this.state.surveys.length < 1}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <button onClick={this.loadWineProfiles} className='base-button' style={{width: 200}}>Load Profiles</button>
                </div>
              </ConditionalDOM>
              <ConditionalDOM render={this.state.surveys.length > 0}>
                {
                  this.state.surveys.map((q, qi) => (
                    <div key={'question' + q.key} style={{ marginTop: qi === 0 ? 0 : 20}}>
                      <p className={'question'}>{q.question}</p>
                      {
                        q.answers.map((a, i) => (
                          <div key={q.key + '_a_' + i}>
                            <p>{`${a.value}: ${a.answers}`}</p>
                          </div>
                        ))
                      }
                    </div>
                  ))
                }
              </ConditionalDOM>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.user.session,
      users: state.user.list
    }
  }
const mapDispatchToProps = (dispatch) => {
  return {
    alert: (type, message) => dispatch(actions.alert(type, message)),
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading()),
    setUserList: (arr) => dispatch(actions.setUserList(arr))
  }
}
  
ManageUsers.propTypes = { 
  user: PropTypes.object.isRequired
}
  
  export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);