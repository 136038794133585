//React Components
import React from 'react';
import ReactDOM from 'react-dom';
//App Level Components
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
//Storage Components
import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
//Other Components & Style Imports
import * as serviceWorker from './serviceWorker';
import './css/App.css';
import './css/Button.css';
import './css/Grid.css';
import './css/Menu.css';
import './css/Text.css';
import './custom-bootstrap.scss';

//Configure Redux and Redux Persist
const persistConfig = { key: 'root', storage };
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
    persistedReducer,
    process.env.NODE_ENV === 'production' ? {} : window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const persistor = persistStore(store);

//Render App Components
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App/>
        </PersistGate>
    </Provider>, document.getElementById('root')
);

// To work offline and load faster, change unregister() to register() below.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
