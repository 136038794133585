import { Component } from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import * as actions from '../actions'

class SnackBar extends Component {
  componentDidUpdate = (prevProps) => {
    if (this.props.message){
      let options = this.getOptions(this.props.type)
      this.props.enqueueSnackbar(this.props.message, options)
      this.props.alert(null, null)
    }
  }
  getOptions = (type) => {
    return {
      key: Date.now(),
      variant: type
    }
  }
  render() {
    return (
      null
    )
  }
}

const mapStateToProps = (state) => ({
  message: state.notifications.message,
  type: state.notifications.type
})
const mapDispatchToProps = (dispatch) => {
  return {
    alert: (type, message) => dispatch(actions.alert(type, message)),
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(SnackBar))
