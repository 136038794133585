import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import { BaseInput, Checkbox, CloseButton } from './'

class EventForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      event: {},
      isOpen: false
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.event && (this.props.event !== prevProps.event || !this.state.isOpen)){
      this.setState({ isOpen: true, event: this.props.event })
    }
  }

  //METHODS
  saveClicked = async () => {
    //TODO: Validate the state and handle any errors BEFORE sending to the server
    this.props.save(this.state.event)
  }
  updateClicked = async () => {
    //TODO: Validate the state and handle any errors BEFORE sending to the server
    this.props.update(this.state.event)
  }
  deleteClicked = async () => {
    //TODO: Validate the state and handle any errors BEFORE sending to the server
    this.props.delete(this.state.event)
  }
  close = () => {
    this.setState({ event: null, isOpen: false })
    this.props.cancel()
  }

  //EVENTS
  handleUpdates = (name, value) => {
    this.setState((prevState) => {
      if (name.indexOf(':') > 0){
        let parts = name.split(':')
        let objName = parts[0]
        let keyName = parts[1]

        prevState[objName][keyName] = value
        return { [objName]: prevState[objName] }
      } else {
        prevState[name] = value
        return { prevState }
      }
    })
  }
  cbChanged = (name, val) => {
    this.setState((prevState) => {
      prevState.event[name] = val
    })
  }

  //RENDER
  render() {
    if (!this.state.event) {
      return null
    }

    return (
      <Modal isOpen={this.state.isOpen} backdrop={true} style={{maxWidth: '95vw', width: '550px'}}>
        <ModalBody>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <p className='dialog-header'>{this.state.event.idevent ? 'Edit Event' : 'Create an event'}</p>
            <CloseButton onClick={this.close}/>
          </div>
          <Row>
            <Col xs='12'>
              <BaseInput name='event:title' startingValue={this.state.event.title} changeHandler={this.handleUpdates} label='Event Name' type='text' required/>
            </Col>
          </Row>
          <Row>
            <Col xs='12' md='4' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <Checkbox 
                name='event:recurring'
                label='Recurring Event?'
                checked={this.state.event.recurring}
                tooltip='This is a recurring event'
                toggled={this.handleUpdates}>
              </Checkbox>
            </Col>
            <Col xs='12' md='8'>
              <BaseInput name='event:recurringtext' disabled={!this.state.event.recurring} startingValue={this.state.event.recurringtext} changeHandler={this.handleUpdates} label='Recurring Schedule' type='text'/>
            </Col>
          </Row>
          { !this.state.event.recurring &&
            <Row>
              <Col xs='12' md='6'>
                <BaseInput name='event:startdate' startingValue={this.state.event.startdate} changeHandler={this.handleUpdates} label='Start Date' type='date' required/>
              </Col>
              <Col xs='12' md='6'>
                <BaseInput name='event:enddate' startingValue={this.state.event.enddate} changeHandler={this.handleUpdates} label='Start Date' type='date'/>
              </Col>
            </Row>
          }
          <Row>
            <Col xs='12'>
              <BaseInput name='event:time' startingValue={this.state.event.time} changeHandler={this.handleUpdates} label='Event Time' type='text' required/>
            </Col>
          </Row>
          <Row>
            <Col xs='12'>
              <BaseInput name='event:description' startingValue={this.state.event.description} changeHandler={this.handleUpdates} label='Description' type='textarea' required/>
            </Col>
          </Row>
          {/* <div style={{marginTop: '25px'}}>
            <Checkbox 
              name='event:pushnotify'
              label='Send push notification'
              checked={this.state.event.pushnotify}
              tooltip='Push notifications are still in progress!'
              // tooltip='Send a push notification to app users with marketing opt in about this event.'
              toggled={this.handleUpdates}>
            </Checkbox>
          </div> */}
          {/* <div style={{marginTop: '10px'}}>
            <Checkbox 
              name='event:emailnotify'
              label='Send email notification'
              checked={this.state.event.emailnotify}
              tooltip='Email notifications are still in progress!'
              // tooltip='Send an email to app users with marketing opt in about this event.'
              toggled={this.handleUpdates}>
            </Checkbox>
          </div> */}
          {
            this.state.event.idevent ? (
              <Row style={{marginTop: '20px'}}>
                <Col xs='6'>
                  <button className='base-button' onClick={this.updateClicked}>Update</button>
                </Col>
                <Col xs='6'>
                  <button className='base-button' onClick={this.deleteClicked}>Delete</button>
                </Col>
              </Row>
            ) : (
              <button className='base-button' style={{marginTop: '20px'}} onClick={this.saveClicked}>Save and Publish</button>
            )
          }
        </ModalBody>
      </Modal>    
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(EventForm)
