import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import Header from './components/Header'
import LoadingWidget from './components/LoadingWidget'
import SnackBar from './components/SnackBar'
import { pages, urls } from './pages'

class App extends Component{
  state = {
    showHeader: false
  }

  render() {
    return (
      <SnackbarProvider 
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        hideIconVariant={false}>
        <React.Fragment>
          <Router>
            <Header show={this.state.showHeader}/>
            <div className='container'>
              <Switch>
                <Route exact path={urls.appRewards} component={ pages.AppRewards }/>
                <Route exact path={urls.appSignup} component={ pages.AppSignup }/>
                <Route exact path={urls.events} component={ pages.Events }/>
                <Route exact path={urls.forgotPassword} component={ pages.ForgotPassword }/>
                <Route exact path={urls.login} component={ pages.Login }/>
                <Route exact path={urls.menu} component={ pages.Menu }/>
                <Route exact path={urls.profile} component={ pages.Profile }/>
                <Route exact path={urls.users} component={ pages.Users }/>
                {/* fatal errors and unmatched paths */}
                <Route component={ pages.Error }/>
              </Switch>
            </div>
          </Router>
          <LoadingWidget/>
          <SnackBar/>
        </React.Fragment>
      </SnackbarProvider>
    )
  }
}

export default App
