import AppRewards from './AppRewards';
import AppSignup from './AppSignup';
import Error from './Error';
import Events from './Events';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import Menu from './Menu';
import Profile from './Profile';
import Users from './Users';

export const pages = { 
    AppRewards,
    AppSignup,
    Error,
    Events,
    ForgotPassword,
    Login,
    Menu,
    Profile,
    Users,
};
const routes = {
    appRewards: '/app-rewards',
    appSignup: '/app-signup',
    error: '/error',
    events: '/events',
    forgotPassword: '/forgot-password',
    login: '/',
    menu: '/menu',
    profile: '/profile',
    users: '/users',
}

routes.privatePages = [routes.appRewards, routes.appSignup, routes.events, routes.menu, routes.profile, routes.users]
export const urls = routes