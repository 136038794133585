import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import * as actions from '../actions'
import { Endpoints } from '../Constants'
import { urls } from '.'
import * as AWSCognito from 'amazon-cognito-identity-js'
import ApiHelper from '../utilities/apiHelper'
import Helpers from '../utilities/helpers.js'
import { BaseButton, BaseInput } from '../components'

class Login extends Component {
  //LIFECYCLE
  constructor(props){
    super(props)
    
    this.state = {
      email: '',
      password: '',
      errorMessage: '',
      isChangePwOpen: false,
      newpassword: '',
      confirmnewpassword: ''
    }
  }
  componentDidMount = () => {
    document.getElementById('body').style.backgroundColor = '#24272a'
    this.props.logout()
    // ApiHelper.deleteAuthToken()
    // ApiHelper.deleteRefreshToken()
  }
  componentWillUnmount = () => {
    document.getElementById('body').style.backgroundColor = '#f8f7ec'
  }

  //METHODS
  onSubmit = (e) => {
    e.preventDefault()
    this.awsLogin()
  }
  tryRefresh = (e) => {
    let refreshToken = ApiHelper.getRefreshToken()

    if (!refreshToken){
      this.props.alert('error', 'no refresh token')
      return
    }

    this.props.showLoading()
  }
  awsLogin = (e) => {
    if (!Helpers.IsValidEmail(this.state.email) || !Helpers.IsValidPassword(this.state.password)){
      this.props.alert('info', 'Please enter a valid email and password.')
      return
    }
    
    this.props.showLoading()

    let poolData = {
      UserPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
    }
    let userPool = new AWSCognito.CognitoUserPool(poolData)
    let userData = {
      Username: this.state.email.toLowerCase(),
      Pool: userPool
    }
    let cognitoUser = new AWSCognito.CognitoUser(userData)
    let authenticationData = {
      Username : this.state.email.toLowerCase(),
      Password : this.state.password,
    }
    let authenticationDetails = new AWSCognito.AuthenticationDetails(authenticationData)

    let page = this

    this.props.setCognitoUser(cognitoUser)

    // send login credentials to cognito and handle response
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        page.cognitoAuthSucceeded(result)
      },
      onFailure: function(err) {
        page.props.closeLoading()
        page.cognitoAuthFailed()
      },
      newPasswordRequired: function(userAttributes, requiredAttributes){
        page.props.closeLoading()
        page.presentChangePwDialog(cognitoUser, this)
      }
    })
  }
  cognitoAuthSucceeded = async (result) => {
    // store tokens from Cognito
    ApiHelper.setAuthExpiry()
    ApiHelper.saveAuthToken(result.getAccessToken().getJwtToken())
    ApiHelper.saveRefreshToken(result.refreshToken.token)

    ApiHelper.reconfigure()

    let response = await ApiHelper.get(Endpoints.Profile)
    this.props.closeLoading()
    if (!response.data.webaccess) {
      this.props.alert('error', 'You are not authorized to use this site.')
      return;
    }
    if (response.data){
      this.props.setUserSession(response.data)
      this.props.history.push(urls.appRewards)
    } else {
      this.showErrorText('The server is temporarily unavailable. Try again in a few minutes.')
    }
  }
  cognitoAuthFailed = () => {
    this.setState({ password: '' })
    this.showErrorText('The email or password was incorrect.')
  }
  showErrorText = (msg) => {
    this.setState({ errorMessage: msg })
  }
  presentChangePwDialog = (cognitoUser, authCtx) => {
    this.setState({ cognitoUser, authCtx })
    this.toggleModal()
  }
  submitNewPassword = (e) => {
    e.preventDefault()

    if (this.state.newpassword !== this.state.confirmnewpassword){
      this.showErrorText('New Password and Confirmation field don\'t match.')
      return
    }

    if (!Helpers.IsValidPassword(this.state.newpassword)){
      this.showErrorText('Your new password must be 8 characters or more, and contain each of the following: One Number, One Uppercase Letter, One Lowercase Letter.')
      return
    }

    this.state.cognitoUser.completeNewPasswordChallenge(this.state.confirmnewpassword, {}, this.state.authCtx)
  }
  toggleModal = () => {
    this.setState({
      isChangePwOpen: !this.state.isChangePwOpen,
      errorMessage: ''
    })
  }
  showErrorText = (msg) => {
    this.setState({ errorMessage: msg })
  }

  //EVENT HANDLERS
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errorMessage: ''
    })
  }
  handleUpdates = (name, value) => {
    this.setState({[name]: value})
  }
  //RENDER
  render() {
    return (
      <div className='content-view dark-view'>
        <div className='centered-container'>
          <div className='content-card singular-card' style={{
              width: '350px', 
              marginTop: '100px', 
              height: '500px', 
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center'}}>
            <div>
              <img src='../images/appicon-dark-trans.png' height='220px' alt='Ripple Winebar'/>
            </div>
            <form onSubmit={this.onSubmit} style={{textAlign: 'center'}}>
              <BaseInput name='email' changeHandler={this.handleUpdates} label='Email' type='email' required autoFocus={true}/>
              <BaseInput name='password' changeHandler={this.handleUpdates} label='Password' type='password' required autoFocus={true}/>
              <BaseButton content='Login' type='submit'/>
              <p hidden={this.state.errorMessage.length < 1} className='err-text'>{this.state.errorMessage}</p>
            </form>
            <div>
              <Link className='link-tag' to={urls.forgotPassword}>Password Reset</Link>
            </div>
          </div>
        </div>

        {/* CHANGE PW DIALOG */}
        <Modal isOpen={this.state.isChangePwOpen} backdrop={true} style={{maxWidth: '95vw', width: '450px'}}>
          <ModalBody>
            <p className='dialog-header'>New Password Required</p>
            <p hidden={this.state.errorMessage.length < 1} className='err-text'>{this.state.errorMessage}</p>
            <p className='temp-offset-for-input'>Your new password must be at leaset 8 characters long.</p>
            <form onSubmit={this.submitNewPassword} autoComplete='off'>
              <BaseInput name='newpassword' changeHandler={this.handleUpdates} label='New Password' type='password' required/>
              <BaseInput name='confirmnewpassword' changeHandler={this.handleUpdates} label='Confirm New Password' type='password' required/>
              <div style={{marginBottom: '10px'}}></div>
              <BaseButton content='Submit' type='submit'/>
            </form>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.session,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    alert: (type, message) => dispatch(actions.alert(type, message)),
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    setCognitoUser: (obj) => dispatch(actions.setCognitoUser(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading())
  }
}

Login.propTypes = { 
  user: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)