import React, { Component } from 'react';

class BaseButton extends Component {
  //METHODS
  click = () => {
    if (this.props.onClick){
      this.props.onClick()
    }
  }
  newId = () => {
    let now = Date.now()
    return now.toString().substr(6)
  }

  //RENDER
  render() {
    return (
      <button 
        id={this.props.id || this.newId()}
        className={'base-button ' + this.props.classes} 
        onClick={this.click}
        type={this.props.type}
        style={this.props.style}
        >
          {this.props.content}
      </button>
    )
  }
}

export default BaseButton;
