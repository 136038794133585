import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as actions from '../actions';
import { Endpoints } from '../Constants';
import { urls } from '.';
import ApiHelper from '../utilities/apiHelper';
import Helpers from '../utilities/helpers.js';
import { BaseButton, BaseInput, ConditionalDOM } from '../components';

class ForgotPassword extends Component {
  //LIFECYCLE
  constructor(props){
    super(props);
    this.state = {
      errorMessage: '',
      submitted: false
    };
  }
  componentDidMount = () => {
    document.getElementById('body').style.backgroundColor = '#24272a'
  }
  componentWillUnmount = () => {
    document.getElementById('body').style.backgroundColor = '#f8f7ec'
  }

  //METHODS
  initiateForgotPassword = async (e) => {
    e.preventDefault();
    if (!Helpers.IsValidEmail(this.state.email)){
      this.props.alert('info', 'Please enter a valid email address.');
      return;
    }

    this.props.showLoading();
    let response = await ApiHelper.post(Endpoints.ForgotPassword, {email: this.state.email});

    if (response.success){
      this.props.alert('success', 'A verification code has been sent to your email.');
      this.setState({ submitted: true })
    } else if (response.status === 404){
      this.props.alert('error', 'That email was not found in the system.')
    } else {
      this.props.alert('error', 'Something went wrong while processing your request.')
    }
    this.props.closeLoading();
  }
  confirmPasswordReset = async (e) => {
    e.preventDefault();
    if (!this.state.verificationcode || this.state.verificationcode.toString().length !== 6){
      this.props.alert('error', 'Please enter a valid verification code.');
      return;
    }
    if (!Helpers.IsValidPassword(this.state.newpassword)){
      this.props.alert('error', 'Please enter a valid password.');
      return;
    }
    if (this.state.newpassword !== this.state.confirmpassword){
      this.props.alert('error', 'Passwords must match.');
      return;
    }

    this.props.showLoading();
    let body = {
      verificationCode: this.state.verificationcode,
      email: this.state.email,
      password: this.state.newpassword
    }
    let response = await ApiHelper.post(Endpoints.ForgotPasswordConfirm, body);

    if (response.success){
      this.props.alert('success', 'Your password has been reset!');
      window.location.href = urls.login
    } else if (response.status === 401){
      this.props.alert('error', 'The verification code you provided did not match the one we sent you.')
    } else {
      this.props.alert('error', 'There was an issue while trying to reset your passowrd. Please try again in a few minutes.')
    }
    this.props.closeLoading();
  }
  showErrorText = (msg) => {
    this.setState({ errorMessage: msg });
  }

  //EVENT HANDLERS
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errorMessage: ''
    });
  }
  handleUpdates = (name, value) => {
    this.setState({[name]: value});
  }
  //RENDER
  render() {
    return (
      <div className='content-view'>
        <div className='centered-container'>
          <div className='content-card singular-card' style={{
              width: '350px', 
              marginTop: '100px', 
              height: '500px', 
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center'}}>
            <div style={{width: '100%', textAlign: 'center'}}>
              <img src='../images/favicon.png' height='140px' alt='Ripple Winebar'/>
            </div>
            <ConditionalDOM render={!this.state.submitted}>
              <form onSubmit={this.initiateForgotPassword} style={{textAlign: 'center'}}>
                <BaseInput name='email' classes='login-input' changeHandler={this.handleUpdates} label='Email' type='email' required autoFocus={true}/>
                <BaseButton content='Reset Password' type='submit'/>
                <p hidden={this.state.errorMessage.length < 1} className='err-text'>{this.state.errorMessage}</p>
            </form>
            </ConditionalDOM>
            <ConditionalDOM render={this.state.submitted}>
              <form onSubmit={this.confirmPasswordReset} style={{textAlign: 'center'}}>
                <BaseInput name='verificationcode' classes='login-input' changeHandler={this.handleUpdates} label='Verification Code' required autoFocus={true}/>
                <BaseInput name='newpassword' classes='login-input' changeHandler={this.handleUpdates} label='New Password' type={'password'} required/>
                <BaseInput name='confirmpassword' classes='login-input' changeHandler={this.handleUpdates} label='Confirm Password' type={'password'} required/>
                <BaseButton content='Submit' type='submit'/>
            </form>
            </ConditionalDOM>
            <div style={{textAlign: 'right', marginTop: '10px'}}>
                <Link className='link-tag' to={urls.login}>Return to Login</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.session
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    alert: (type, message) => dispatch(actions.alert(type, message)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading())
  }
}

ForgotPassword.propTypes = { 
  user: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);