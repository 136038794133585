import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { BaseInput, Checkbox, UserForm } from '../components'
import { Col, Row } from 'reactstrap'
import * as actions from '../actions'
import ApiHelper from '../utilities/apiHelper'
import { Endpoints } from '../Constants'
import { urls } from '../pages'
import helpers from '../utilities/helpers'

class AppSignup extends Component{
  constructor(props){
    super(props)
    this.state = {
      isSignupOpen: false,
      selectedUser: {},
      signups: [],
      user: {}
    }
  }
  componentDidMount = async () => {
    if (ApiHelper.isAuthExpired()){
      let refreshed = await ApiHelper.tryRefresh();
      if (!refreshed){
        this.props.history.push(urls.login);
        return;
      }
    }
    this.loadData()
  }

  //METHODS
  loadData = async () => {
    this.props.showLoading()
    let response = await ApiHelper.get(Endpoints.AppSignUp)
    this.props.closeLoading()
    if (!response.success && response.status === 404){
      return
    } else if (!response.success) {
      this.props.alert('error', 'There was an error in getting app presign up list: ' + response.status)
      return
    }

    this.setState({signups: response.data})
  }
  toggleSignupDialog = () => {
    this.setState({isSignupOpen: !this.state.isSignupOpen})
  }
  createUser = async (e) => {
    if (!helpers.IsValidEmail(this.state.user.email)){
      this.props.alert('error', 'Please enter a valid email address.')
      return
    }
    this.props.showLoading()
    let response = await ApiHelper.put(Endpoints.AppSignUp, this.state.user)
    this.props.closeLoading()

    if (!response.success){
      let message
      switch(response.status){
        case 409:
          message = 'That email address has already been pre-registerd, or already has an account in system.'
          break;
        default:
          message = 'There was an issue while registering this email address.'
      }
      this.props.alert('error', message)
      return
    }

    this.props.alert('success', 'This person\'s info has been saved, and they\'ve been asked to verify their email address. Yay!')
    this.loadData()
  }
  userClicked = (selectedUser) => {
    this.setState({ selectedUser, enableUpdate: false, isAddFormVisible: false });
  }
  unselectUser = () => {
    // this.setState({selectedUser: {}})
  }
  closeDetail = () => {
    this.setState({ selectedUser: {}, enableUpdate: false, isAddFormVisible: false });
  }
  updateUser = async (user) => {
    if (!user.iduser){
      this.props.alert('error', 'Please select a user.');
      return;
    }
    if (!user.firstname || user.firstname.length < 1 || !user.lastname || user.lastname.length < 1){
      this.props.alert('error', 'Give the user a first name and a last name.');
      return;
    }

    this.props.showLoading();
    let response = await ApiHelper.post(Endpoints.Users, user);

    if (response.success){
      this.loadData();
      this.props.alert('success', `Updated user account.`);
    } else {
      this.props.alert('error', 'Something went wrong while trying to update the user account.');
    }
    this.props.closeLoading();
  }
  deleteUser = async (user) => {
    let message = !user.authid ? `Are you sure that you want to delete this app pre-signup request? Their email address will be removed from the system.` 
      : `Are you sure that you want to delete this user's account permanently? Effective immediately, they will be unable to access the app${user.webaccess ? ' and admin portal.' : '.'}`;
    let confirmed = window.confirm(message);
    if (!confirmed){
      return;
    }
    if (!user.iduser){
      this.props.alert('error', 'Please select a user.');
      return;
    }

    this.props.showLoading();

    let params = `?id=${user.iduser}`
    let response = await ApiHelper.delete(Endpoints.Users + params);

    if (response.success){
      this.loadData();
      this.props.alert('info', `Deleted user account.`);
      this.unselectUser()
    } else {
      this.props.alert('error', 'Something went wrong while trying to delete the user account.');
    }
    this.props.closeLoading();
  }

  //EVENT HANDLERS
  handleUpdates = (name, value) => {
    this.setState((prevState) => {
      if (name.indexOf(':') > 0){
        let parts = name.split(':')
        let objName = parts[0]
        let keyName = parts[1]

        prevState[objName][keyName] = value
        return { [objName]: prevState[objName] }
      } else {
        prevState[name] = value
        return { prevState }
      }
    })
  }
  optInChanged = (name, val) => {
    this.setState((prevState) => {
      prevState.user[name] = val
    })
  }

  //RENDER
  render() {
    return (
      <div className='content-view'>
        <div className='centered-container'>
          <div className='content-card singular-card'>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <p className='page-header'>Add new App Signup</p>
              {/* <button className='base-button' onClick={this.toggleSignupDialog}>Add New</button> */}
            </div>
            <Row>
              <Col xs='12' md='6'>
                <BaseInput name='user:firstname' changeHandler={this.handleUpdates} label='First Name' type='text' required/>
              </Col>
              <Col xs='12' md='6'>
                <BaseInput name='user:lastname' changeHandler={this.handleUpdates} label='Last Name' type='text' required/>
              </Col>
            </Row>
            <Row>
              <Col xs='12' md='6'>
                <BaseInput name='user:email' changeHandler={this.handleUpdates} label='Email' type='email' required/>
              </Col>
              <Col xs='12' md='6'>
                <BaseInput name='user:phonenumber' changeHandler={this.handleUpdates} label='Phone Number' type='text'/>
              </Col>
            </Row>
            <div style={{marginTop: '20px'}}>
              <Checkbox 
                name='marketingallowed'
                label='Marketing Opt In'
                tooltip='The individual indicated that it is okay to email them with marketing and promotional offers.'
                tooltipPlacement='top'
                toggled={this.optInChanged}>
              </Checkbox>
            </div>
            <div className='centered-row' style={{marginTop: '25px'}}>
              <button className='base-button' onClick={this.createUser}>Save User Info</button>
            </div>
          </div>
          <div className='content-card singular-card'>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <p className='page-header'>Existing Signups</p>
            </div>
            {
              this.state.signups.length < 1 ? (
                <p className='italic-text'>Use the form above to start adding app sign ups.</p>
              ) : (
                <div>
                  <p className='italic-text'>{ 'Select a user below to view details'}</p>
                  <div className='grid-container'>
                    <div className='grid-row header-row'>
                      <div className='grid-cell' style={{width: '120px'}}><p className='grid-header'>First Name</p></div>
                      <div className='grid-cell' style={{width: '160px'}}><p className='grid-header'>Last Name</p></div>
                      <div className='grid-cell' style={{width: '300px'}}><p className='grid-header'>Email</p></div>
                    </div>
                    {
                      this.state.signups.map((item, i) => (
                        <div key={'item'+item.iduser} className={`grid-row row-hover ${i % 2 === 0 ? '' : 'alt-row'}`} onClick={() => this.userClicked(item)}>
                          <div className='grid-cell' style={{width: '120px'}}><p className='grid-text'>{item.firstname}</p></div>
                          <div className='grid-cell' style={{width: '160px'}}><p className='grid-text'>{item.lastname}</p></div>
                          <div className='grid-cell' style={{width: '300px'}}><p className='grid-text'>{item.email}</p></div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              )
            }
          </div>
        </div>

        <UserForm 
          user={this.state.selectedUser}
          adminEnabled={false}
          update={this.updateUser}
          delete={this.deleteUser}
          cancel={this.unselectUser}>
        </UserForm>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.user.session,
    }
}
const mapDispatchToProps = (dispatch) => {
  return {
    alert: (type, message) => dispatch(actions.alert(type, message)),
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading())
  }
}

AppSignup.propTypes = { 
  user: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AppSignup)