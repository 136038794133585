import { DateTime } from 'luxon'

export default {
  //VALIDATION
  IsValidEmail: (email) => {
      var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(email);
  },
  IsValidPassword: (password) => {
      if (!password || password.length < 8){
          return false;
      }
      
      return true;
  },
  //FORMATTING
  FormatDollars: (num) => {
    if (!num || num === 0 || num === "0"){
      return '$0.00';
    }
    
    var parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (parts[1] && parts[1].length === 1){
      parts[1] = parts[1] + "0";
    }
    let str = parts.join('.');
    let dollarsign = str.indexOf('$');
    if (dollarsign < 0){
      str = '$' + str;
    }
    if (str.indexOf('.') < 0){
      str += '.00';
    }
    return str;
  },
  PadTimePart: (val) => {
    var valString = val + "";
    if (valString.length < 2) {
      return "0" + valString;
    } else {
      return valString;
    }
  },
  FormatHours: (hrs) => {
      if (!hrs) { return '0' }
      
      let val = hrs.toString();
      let dIdx = val.indexOf('.')
      if (dIdx > 0){
          val = val.substring(0, dIdx + 2);
      } else {
          val += '.0'
      }

      return val;
  },
  FormatTimeText: (sec) => {
    if (sec <= 0){
      return '00:00';
    }
    let seconds =  this.PadTimePart(sec % 60);
    let minutes = this.PadTimePart(parseInt(sec / 60) % 60);
    let value = `${minutes}:${seconds}`;
    let hours = parseInt(sec / 60 / 60);

    if (hours > 0){
      value = this.PadTimePart(hours) + ':' + value;
    }

    return value;
  },
  ConvertSecToHrs: (sec) => {
      if (!sec || sec === 0) { return '0' }
      
      let val = (sec / 3600).toString();
      let dIdx = val.indexOf('.')
      if (dIdx > 0){
          val = val.substring(0, dIdx + 2);
      } else {
          val += '.0'
      }
      return val;
  },
  GetTodaysDate: () => {
    let dt = new Date();
    let y = dt.getFullYear(); //no pad until year the 10,000..
    let m = (dt.getMonth() + 1).toString().padStart(2, '0'); //pad to 2 digits.
    let d = dt.getDate().toString().padStart(2, '0'); //pad to 2 digits.
    return `${y}-${m}-${d}`;
  },
  GetWeekStart: () => {
    let dt = new Date();

    for(let d = 0; d < 7; d++){
      dt = new Date();
      dt.setDate(dt.getDate() - d);
      if (dt.getDay() === 0){
        break;
      }
    }

    return this.FormatDt(dt);
  },
  GetMonthStart: () => {
    let dt = new Date();

    dt.setDate(1);

    return this.FormatDt(dt);
  },
  FormatDt: (dt) => {
    let y = dt.getFullYear(); //no pad until year the 10,000..
    let m = (dt.getMonth() + 1).toString().padStart(2, '0'); //pad to 2 digits.
    let d = dt.getDate().toString().padStart(2, '0'); //pad to 2 digits.
    return `${y}-${m}-${d}`;
  },
  FormatShortDate: (date) => {
    if (!date){ return '' }
    return DateTime.fromSQL(date).toFormat('MMM d')
  },
  IsToday: (date) => {
    return date === this.GetTodaysDate();
  },
  Pluralize: (word, n, includeN) => {
    let str = word;
    if (n !== 1){
      str += 's';
    }
    return includeN ? n + ' ' + str : str;
  },
  SortByThenBy: (a, b) => {
    if (a > b) return +1;
    if (a < b) return -1;
    return 0;
  },
}
