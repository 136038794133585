import React, { Component } from 'react'

class CloseButton extends Component {
  constructor(props){
    super(props)
    this.state = {}
  }
  render() {
    return (
      <img onClick={this.props.onClick} src='../images/x-icon.png' height='30px' style={{cursor: 'pointer'}} alt='Close'/>
    )
  }
}

export default CloseButton
