import React, { Component } from 'react'

class FileDrop extends Component {
  //LIFECYCLE
  componentDidMount() {
    let div = document.getElementById('fdArea')
    div.addEventListener('dragenter', this.handleDragIn)
    div.addEventListener('dragleave', this.handleDragOut)
    div.addEventListener('dragover', this.handleDrag)
    div.addEventListener('drop', this.handleDrop)
  }
  componentWillUnmount() {
    let div = document.getElementById('fdArea')
    if (!div){ 
      return 
    }
    div.removeEventListener('dragenter', this.handleDragIn)
    div.removeEventListener('dragleave', this.handleDragOut)
    div.removeEventListener('dragover', this.handleDrag)
    div.removeEventListener('drop', this.handleDrop)
  }
  state = {
    dragging: false,
    file: null
  }
  dragCounter = 0

  //METHODS
  openFilePicker = (e) => {
    e.preventDefault()
    let fileElem = document.getElementById('fileinput')
    fileElem.click()
  }

  //EVENTS
  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({dragging: true})
    }
  }
  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.setState({dragging: false})
    }
  }
  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({dragging: false})
    let file = e.dataTransfer.files[0]
    
    if (file && file.name.indexOf('.pdf') > 0) {
      this.setState({file})
      this.props.fileReceived(file)
      e.dataTransfer.clearData()
      this.dragCounter = 0    
    }
  }
  fileSelectionChanged = (e) => {
    let fileElement = document.getElementById('fileinput')
    if (fileElement.files.length < 1){
      return
    }
    this.props.fileReceived(fileElement.files[0])
  }

  //RENDER
  render() {
    return (
      <div className='fd-container'>
        <div id='fdArea' className={`fd-area ${this.state.dragging ? 'fd-active' : ''}`}>
          <input type='file' id='fileinput' accept='.xlsx' style={{display: 'none'}} onChange={this.fileSelectionChanged}/>
          <p className='fd-instruction'>{this.props.instruction}</p>
          {
            this.props.showBrowse ? 
              <button className='base-button' onClick={this.openFilePicker}>Browse</button>
              : null
          }
        </div>
      </div>
    )
  }
}
export default FileDrop