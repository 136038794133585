import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as actions from '../actions'
import ApiHelper from '../utilities/apiHelper'
import { Days, Endpoints } from '../Constants'
import { urls } from '../pages'
import { BaseDropDown, BaseInput } from '../components'
import { Col, Row } from 'reactstrap'

class Page extends Component{
  constructor(props){
    super(props)
    this.state = {
      rewardCode: '',
      rewards: [],
      selectedDay: -1,
      updatedDay: -1
    }
  }
  componentDidMount = async () => {
    if (ApiHelper.isAuthExpired()){
      let refreshed = await ApiHelper.tryRefresh();
      if (!refreshed){
        this.props.history.push(urls.login);
        return;
      }
    }
    this.loadData()
  }

  //METHODS
  loadData = async () => {
    this.props.showLoading()
    let response = await ApiHelper.get(Endpoints.Rewards)
    if (!response.success) {
      this.props.closeLoading()
      this.props.alert('error', 'There was an error in getting app rewards list: ' + response.status)
      return
    }

    let doublePtsResponse = await ApiHelper.get(Endpoints.Settings + '?setting=doublepointsday')
    if (!doublePtsResponse.success) {
      this.props.alert('error', 'There was an error while loading the Double Points Day setting.')
    }

    this.props.closeLoading()
    this.setState({rewards: response.data, selectedDay: Days[doublePtsResponse.data.doublepointsday]})
  }
  rewardClicked = (item) => {
    console.log('clicked reward', item)
  }
  updateDoublePointsDay = async () => {
    if (!window.confirm('Are you sure that you would like to change Double Points Day? This will take effect immediately, but it will not affect any checks that have already been redeemed today (even if the new day matches the current day of the week).')){
      return
    }
    this.props.showLoading()
    let selectedDay = this.state.updatedDay

    let response = await ApiHelper.post(Endpoints.Settings, { doublePointsDay: selectedDay })
    this.props.closeLoading()

    if (!response.success) {
      this.props.alert('error', 'There was an error while updating the Double Points Day setting.')
      return
    }

    this.setState({ selectedDay, updatedDay: -1 })
    this.props.alert('success', `Double Points Day has been changed to ${Days[selectedDay].name}`)
  }
  checkRewardCode = async () => {
    let response = await ApiHelper.post(Endpoints.RewardCode, { rewardCode: this.state.rewardCode });
    
    if (!response.success) {
      this.props.alert('error', response.statusCode >= 500 ? 'There was an unknown problem. Please try again.' : 'The given Reward Code is not valid.')
      return;
    }

    this.setState({ rewardCode: '' });
    this.props.alert('success', 'The Reward Code was valid, and has been deducted from the user\'s account.');
  }

  //EVENT HANDLERS
  handleUpdates = (name, value) => {
    this.setState((prevState) => {
      if (name.indexOf(':') > 0){
        let parts = name.split(':')
        let objName = parts[0]
        let keyName = parts[1]

        prevState[objName][keyName] = value
        return { [objName]: prevState[objName] }
      } else {
        prevState[name] = value
        return { prevState }
      }
    })
  }
  optInChanged = (name, val) => {
    this.setState((prevState) => {
      prevState.user[name] = val
    })
  }
  doublePointsChanged = (selectedDay) => {
    this.setState({ updatedDay: Days.indexOf(selectedDay) })
  }

  //RENDER
  render() {
    return (
      <div className='content-view' style={{marginBottom: '50px'}}>
        <div className='centered-container'>
          <div className='content-card singular-card'>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <p className='page-header'>Current Rewards</p>
            </div>
            {
              this.state.rewards.length < 1 ? (
                <p className='italic-text'>Use the Create Reward button to start adding rewards for the app.</p>
              ) : (
                <div>
                  {/* TODO: Edit rewards */}
                  {/* <p className='italic-text'>Click a reward below to make changes</p> */}
                  <div className='box-of-cards'>
                    {
                      this.state.rewards.map((item, i) => (
                        <div key={'item'+i} className='item-card' style={{display: 'flex', flexDirection: 'row'}} onClick={() => this.rewardClicked(item)}>
                          <div style={{height: '100px', width: '100px'}}>
                            <img src={item.image} style={{height: '100%', width: '100%'}} alt='' />
                          </div>
                          <div style={{padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <h3 className='item-header'>{item.points + ' points'}</h3>
                            <p className='item-detail'>{item.title}</p>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              )
            }
          </div>
          <div className='content-card singular-card'>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <p className='page-header'>Check a Reward Code</p>
            </div>
            <div>
              <p className='italic-text'>Submit a customer's code here to check it's validity. If the code is valid, it will marked as redeemed.</p>
            </div>
              <Row style={{marginTop: '20px', marginBottom: '20px'}}>
                <Col xs='6'>
                  <BaseInput name='rewardCode' startingValue={this.state.rewardCode} changeHandler={this.handleUpdates} label='Reward Code' type='number'/>
                </Col>
                <Col xs='6'>
                  <button className='base-button' style={{marginTop: '10px'}} onClick={this.checkRewardCode}>Submit</button>
                </Col>
              </Row>
          </div>
          <div className='content-card singular-card'>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <p className='page-header'>Double Points Day</p>
            </div>
            <Row style={{marginTop: '20px', marginBottom: '20px'}}>
              <Col xs='6'>
                <BaseDropDown 
                  items={Days}
                  style={{height: '50px', width: '100%'}}
                  titleProp={'name'}
                  placeholder={this.state.selectedDay.name ? this.state.selectedDay.name : 'Day of the week'}
                  selectionChanged={this.doublePointsChanged}>
                </BaseDropDown>
              </Col>
              <Col xs='6'>
                <button className='base-button' disabled={this.state.updatedDay < 0} onClick={this.updateDoublePointsDay}>Update</button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.user.session,
    }
  }
const mapDispatchToProps = (dispatch) => {
  return {
    alert: (type, message) => dispatch(actions.alert(type, message)),
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading())
  }
}

Page.propTypes = { 
  user: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Page)