import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Modal, ModalBody, Row, Spinner } from 'reactstrap'
import { BaseInput, FileDrop } from '.'
import mime from 'mime-types'
import ApiHelper from '../utilities/apiHelper'
import { Endpoints } from '../Constants'
import * as actions from '../actions'

class MenuItemModal extends Component {
  constructor(props){
    super(props)
    this.state = {
      isOpen: false,
      item: {},
      uploading: false
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.item && (this.props.item !== prevProps.item || !this.state.isOpen)){
      this.setState({ isOpen: true, item: this.props.item })
    }
  }

  //METHODS
  saveClicked = async () => {
    this.setState({ isOpen: false, item: {} })
    this.props.save(this.state.item)
  }
  uploadFile = async (file) => {
    this.setState({ uploading: true })

    let fileParts = file.name.split('.')
    let fileName = fileParts[0]
    let fileType = mime.contentType(fileParts[1])

    let response = await ApiHelper.post(Endpoints.PreSignUpload, { fileName, fileType })

    if (response.success){
      let { signedRequest, url } = response.data
      // Put the fileType in the headers for the upload
      let headers = { 'Content-Type': fileType }
      let request = await ApiHelper.put(signedRequest, file, headers)

      if (request.success){
        let { item } = this.state
        item.tastingNotesUrl = url
        this.setState({ item })
        this.props.alert('success', `${file.name} was uploaded!`)
      } else {
        this.props.alert('error', `Failed to upload ${file.name}`)
      }
    } else {
      this.props.alert('error', `Failed to sign the upload ${file.name}`)
    }
    this.setState({ uploading: false })
  }
  removePdfLink = async () => {
    if (!window.confirm('Are you sure that you want to remove the tasting notes PDF from this menu item? The updated menu will still need to be published.')){
      return
    }

    let item = this.state.item
    item.tastingNotesUrl = ''
    this.setState({ item })
  }

  //EVENTS
  handleUpdates = (name, value) => {
    this.setState((prevState) => {
      if (name.indexOf(':') > 0){
        let parts = name.split(':')
        let objName = parts[0]
        let keyName = parts[1]

        prevState[objName][keyName] = value
        return { [objName]: prevState[objName] }
      } else {
        prevState[name] = value
        return { prevState }
      }
    })
  }
  cbChanged = (name, val) => {
    this.setState((prevState) => {
      prevState.event[name] = val
    })
  }
  fileReceived = (file) => {
    if (!file) {
      return
    }

    this.uploadFile(file)
  }

  //RENDER
  render() {
    return (
      <Modal isOpen={this.state.isOpen} backdrop={true} style={{maxWidth: '95vw', width: '550px'}}>
        <ModalBody>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <p className='dialog-header'>{'Edit menu item'}</p>
            {/* <CloseButton onClick={this.cancel}/> */}
          </div>

          <Row>
            <Col xs='12' md='6'>
              <BaseInput name='item:name' startingValue={this.state.item.name} changeHandler={this.handleUpdates} label='Item Name' type='text' required/>
            </Col>
            <Col xs='12' md='6'>
              <BaseInput name='item:varietal' startingValue={this.state.item.varietal} changeHandler={this.handleUpdates} label='Varietal' type='text' required/>
            </Col>
          </Row>
          <FileDrop instruction={'Drag tasting notes here'} showButton={false} fileReceived={this.fileReceived} />

          {
            this.state.item.tastingNotesUrl &&
              <p className='link-tag' style={{marginTop: '20px'}} onClick={this.removePdfLink}>
                Remove PDF
              </p>
          }

          {
            this.state.uploading
            ? <button className='base-button' disabled style={{marginTop: '20px', alignItems: 'center', justifyContent: 'center'}} onClick={this.saveClicked}>
                <p style={{display: 'inline-block'}}>Uploading</p>
                <Spinner size='sm' color='light' style={{marginLeft: 10, padding: 0}} />
              </button>
            : <button className='base-button' style={{marginTop: '20px'}} onClick={this.saveClicked}>Save Changes</button>
          }
        </ModalBody>
      </Modal>    
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = (dispatch) => {
  return {
    alert: (type, message) => dispatch(actions.alert(type, message)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemModal)
