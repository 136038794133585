import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { urls } from '../pages'
import * as actions from '../actions'
import { BaseButton } from './'
import { Collapse } from 'reactstrap'

class Header extends Component {
  //LIFECYCLE
  constructor(props){
    super(props)
    
    this.state = {
      loggedIn: false,
      isMenuOpen: false
    }
  }
  componentDidMount = () => {
    this.onRouteChanged()

    let that = this
    window.onclick = function(event, cb) {
      if (event.target.id !== 'wrapper' && event.target.id !== 'widget') {
        that.setState({isMenuOpen: false})
      }
    }
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location){
      this.onRouteChanged()
    }
    return
  }
  isHeaderPage = () => {
    return this.props.location.pathname !== urls.login && this.props.location.pathname !== urls.forgotPassword
  }

  //METHODS
  toggleMenu = (e) => {
    this.setState({isMenuOpen: !this.state.isMenuOpen})
  }
  logout = () => {
    document.getElementById('root').classList.remove('show-modal')
    this.props.history.push(urls.login)
  }

  //EVENTS
  logoClicked = () => {
    let rootPages = [urls.login, urls.appSignup]
    if (rootPages.indexOf(this.props.location.pathname) < 0){
      this.props.history.push(this.state.loggedIn ? urls.appSignup : urls.login)
    }
  }
  onRouteChanged = () => {
    let showMenu = urls.privatePages.indexOf(this.props.location.pathname) > -1
    this.setState({loggedIn: showMenu})
  }


  //RENDER
  render() {
    return this.state.loggedIn ? (
      <div className='sticky-header'>
        <img src='../images/logo-offwhite.png' height='50px' alt='Ripple Winebar Logo' style={{cursor: 'pointer', display: 'inline-block', padding: '3px'}} onClick={this.logoClicked}/>
        <div className='menu-widget'>
          <BaseButton id='wrapper' classes='menu-button' content={this.props.user.fullname} onClick={this.toggleMenu}></BaseButton>
          <Collapse id='widget' isOpen={this.state.isMenuOpen}>
            <a className='nav-link' href={urls.appSignup}>App Signups</a>
                <a className='nav-link' href={urls.appRewards}>App Rewards</a>
                <a className='nav-link' href={urls.menu}>App Menu</a>
            <a className='nav-link' href={urls.events}>Events</a>
            <a className='nav-link' href={urls.users}>Users</a>
            <a className='nav-link' href={urls.profile}>Profile</a>
            <a className='nav-link logout-link' href={urls.login}>Logout</a>
          </Collapse>
        </div>
      </div>
    ) : null
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.session
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading()),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
