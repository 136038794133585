import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import { Endpoints } from '../Constants'
import ApiHelper from '../utilities/apiHelper'
import { BaseInput, Checkbox, CloseButton } from './'
import * as actions from '../actions'
import ConditionalDOM from './ConditionalDOM'

class UserForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      isOpen: false,
      user: {
        webaccess: props.adminEnabled || false
      }
    }
  }

  componentDidUpdate = (prevProps) => {  
    // if (this.props.user.iduser !== prevProps.user.iduser){
    //   this.setState({isOpen: this.props.user.iduser !== null, user: this.props.user})
    // }
    if (this.props.user && (this.props.user !== prevProps.user || !this.state.isOpen)){
      this.setState({ isOpen: true, user: this.props.user })
    }
  }

  //METHODS
  saveClicked = async () => {
    //TODO: Validate the state and handle any errors BEFORE sending to the server
    this.props.save(this.state.user)
  }
  updateClicked = async () => {
    //TODO: Validate the state and handle any errors BEFORE sending to the server
    this.props.update(this.state.user)
  }
  deleteClicked = async () => {
    //TODO: Validate the state and handle any errors BEFORE sending to the server
    this.props.delete(this.state.user)
  }
  addPointsClicked = async () => {
    let response = await ApiHelper.post(Endpoints.UserPoints, {iduser: this.state.user.iduser, points: this.state.user.additionalpoints})
    if (!response.success){
      this.props.alert('error', 'Failed to add points to the user\'s account.')
      return
    }
    this.setState({ user: {...this.state.user, points: parseInt(this.state.user.points) + parseInt(this.state.user.additionalpoints)}})
    this.props.alert('success', 'Points added.')
  }
  resetPassword = async () => {
    let response = await ApiHelper.post(Endpoints.ResetUserPw, this.state.user)
    if (!response.success){
      this.props.alert('error', 'Failed to reset this user\'s password.')
      return
    }
    this.props.alert('success', 'User password reset!')
  }
  close = () => {
    this.setState({ user: null, isOpen: false })
    this.props.cancel()
  }

  //EVENTS
  handleUpdates = (name, value) => {
    this.setState((prevState) => {
      if (name.indexOf(':') > 0){
        let parts = name.split(':')
        let objName = parts[0]
        let keyName = parts[1]

        prevState[objName][keyName] = value
        return { [objName]: prevState[objName] }
      } else {
        prevState[name] = value
        return { prevState }
      }
    })
  }
  cbChanged = (name, val) => {
    this.setState((prevState) => {
      prevState.user[name] = val
    })
  }
  getPref = (key) => {
    let setting = 'pref' + key
    let option = null

    if (key === 'wineworld') {
      option = WineProfile.WineWorlds.find(el => el.key === this.state.user[setting])
    } else if (key === 'organicinterest'){
      option = WineProfile.OrganicInterest.find(el => el.key === this.state.user[setting])
    }

    return option ? option.label : 'n/a'
  }

  //RENDER
  render() {
    if (!this.state.user) {
      return null
    }

    return (
      <Modal isOpen={this.state.isOpen} backdrop={true} style={{maxWidth: '95vw', width: this.state.user.iduser ? '900px' : '550px'}}>
        <ModalBody>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <p className='dialog-header'>{this.state.user.iduser ? 'Manage User' : 'Create a user'}</p>
            <CloseButton onClick={this.close}/>
          </div>

          <Row>
            <Col>
              <Row>
                <Col xs='6'>
                  <BaseInput name='user:firstname' startingValue={this.state.user.firstname} changeHandler={this.handleUpdates} label='First Name' type='text' required/>
                </Col>
                <Col xs='6'>
                  <BaseInput name='user:lastname' startingValue={this.state.user.lastname} changeHandler={this.handleUpdates} label='Last Name' type='text' required/>
                </Col>
              </Row>
              <Row>
                <Col xs='6'>
                  <BaseInput name='user:email' disabled={true} startingValue={this.state.user.email} changeHandler={this.handleUpdates} label='Email' type='email' required/>
                </Col>
                <Col xs='6'>
                  <BaseInput name='user:phonenumber' disabled={true} startingValue={this.state.user.phonenumber} changeHandler={this.handleUpdates} label='Phone Number' type='text'/>
                </Col>
              </Row>
              <div style={{marginTop: '20px'}}>
                <Checkbox 
                  name='marketingallowed'
                  label='Marketing Opt In'
                  checked={this.state.user.marketingallowed}
                  tooltip='The individual indicated that it is okay to email them with marketing and promotional offers.'
                  tooltipPlacement='top'
                  disabled
                  toggled={this.cbChanged}>
                </Checkbox>
              </div>
              {
                this.props.adminEnabled ? (
                  <div style={{marginTop: '20px'}}>
                    <Checkbox 
                      name='webaccess'
                      label='Web site access / Admin account'
                      checked={this.state.user.webaccess}
                      tooltip='The new user will be allowed to access the admin portal, and use all of the features available to admins.'
                      tooltipPlacement='top'
                      toggled={this.cbChanged}>
                    </Checkbox>
                  </div>
                ) : null
              }
              {
                this.state.user.iduser ? (
                  <div>
                    <Row style={{marginTop: '20px', marginBottom: '20px'}}>
                      <Col xs='6'>
                        <button className='base-button' onClick={this.updateClicked}>Update</button>
                      </Col>
                      <Col xs='6'>
                        <button className='base-button' onClick={this.deleteClicked}>Delete</button>
                      </Col>
                    </Row>
                    <p className='dialog-header'>Points (Current balance: {this.state.user.points})</p>
                    <Row style={{marginBottom: '20px'}}>
                      <Col xs='8'>
                        <BaseInput name='user:additionalpoints' changeHandler={this.handleUpdates} label='Add Points' type='number'/>
                      </Col>
                      <Col xs='4'>
                        <div className='temp-offset-for-input'>
                          <button className='base-button' onClick={this.addPointsClicked}>Add Points</button>
                        </div>
                      </Col>
                    </Row>
                    {/* <p className='dialog-header'>Password</p>
                    <Row>
                      <Col xs='12'>
                        <button className='base-button' onClick={this.resetPassword}>Reset Password</button>
                      </Col>
                    </Row> */}
                  </div>
                ) : (
                  <button className='base-button' style={{marginTop: '20px'}} onClick={this.saveClicked}>Create Account</button>
                )
              }
            </Col>
            <ConditionalDOM render={this.state.user.iduser}>
              <Col style={{borderLeft: '2px solid black'}}>
                <p className='dialog-header'>{'Wine Profile'}</p>
                <p className={'question'}>
                  {'THE TYPES OF WINE I LIKE TO DRINK INCLUDE: (SELECT ALL THAT APPLY)'}
                </p>
                <p>{this.state.user.prefswinetypes || 'n/a'}</p>

                <div style={{marginTop: 20}}></div>
                <p className={'question'}>
                  {'THE GRAPE VARIETALS I PREFER ARE: (SELECT ALL THAT APPLY)'}
                </p>
                <p>{this.state.user.prefsvarietals || 'n/a'}</p>

                <div style={{marginTop: 20}}></div>
                <p className={'question'}>
                  {'I LEAN MORE TOWARDS WINE THAT IS:'}
                </p>
                <p>{this.getPref('wineworld')}</p>

                <div style={{marginTop: 20}}></div>
                <p className={'question'}>
                  {'I AM INTERESTED IN NATURAL, ORGANIC, AND/OR BIODYNAMIC WINE.'}
                </p>
                <p>{this.getPref('organicinterest')}</p>
              </Col>
            </ConditionalDOM>
          </Row>

        </ModalBody>
      </Modal>    
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = (dispatch) => {
  return {
    alert: (type, message) => dispatch(actions.alert(type, message)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)



const WineProfile = {
  WineTypes: [
    'RED',
    'WHITE',
    'ROSE',
    'SPARKLING',
    'FORTIFIED',
    'SWEET'
  ],
  Varietals: [
    'ALBARIÑO',
    'BARBERA',
    'CABERNET FRANC',
    'CABERNET SAUVIGNON',
    'CHARDONNAY',
    'CHENIN BLANC',
    'GAMAY',
    'GRENACHE/GARNACHA',
    'MALBEC',
    'MERLOT',
    'MOSCATO',
    'NEBBIOLO',
    'PINOT GRIGIO/PINOT GRIS',
    'PINOT NOIR',
    'PETITE SIRAH',
    'RIESLING',
    'SANGIOVESE',
    'SAUVIGNON BLANC',
    'SYRAH/SHIRAZ',
    'TEMPRANILLO',
    'VIOGNIER',
    'ZINFANDEL',
    'OTHER FUN GRAPES!'
  ],
  WineWorlds: [
    { label: 'NEW WORLD', value: 'new' },
    { label: 'OLD WORLD', value: 'old' },
    { label: 'BOTH', value: 'both' },
    { label: 'I’M NOT SURE', value: 'notsure' }
  ],
  OrganicInterest: [
    { label: 'YES! PLEASE TELL ME MORE!', value: 'yes' },
    { label: 'SURE, I LIKE ALL WINE.', value: 'sure' },
    { label: 'NO, NOT REALLY.', value: 'no' }
  ]
}
