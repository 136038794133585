import React, { Component } from 'react';

class ArrowButton extends Component {
  //METHODS
  click = () => {
    if (this.props.onClick){
      this.props.onClick()
    }
  }
  newId = () => {
    let now = Date.now()
    return now.toString().substr(6)
  }

  getRotation = () => {
    switch (this.props.direction) {
      case 'up':
        return { transform: 'rotate(-90deg)' }
      case 'down':
        return { transform: 'rotate(90deg)' }
      case 'left':
        return { transform: 'rotate(180deg)' }
      default:
        return {}
    }
  }

  //RENDER
  render() {
    return (
      <button 
        className='move-button' 
        style={{marginRight: 5}} 
        disabled={this.props.disabled} 
        onClick={this.props.onClick}
      >
        <img
          src='../images/chevron.png'
          className='arrow-img'
          style={this.getRotation()} 
          alt={this.props.direction}
        />
      </button>
    )
  }
}

export default ArrowButton;
