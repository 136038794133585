import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as actions from '../actions'
import ApiHelper from '../utilities/apiHelper'
import { Endpoints } from '../Constants'
import { urls } from '../pages'
import { BaseButton, CloseButton, MenuItemModal } from '../components'
import { Collapse } from 'reactstrap'
import ArrowButton from '../components/ArrowButton'

class Page extends Component{
  constructor(props){
    super(props)
    this.state = {
      appMenuSections: [],
      openAppSectionId: null,
      openUpserveCategoryId: null,
      selectedItem: null,
      upserveItems: [],
    }
  }
  componentDidMount = async () => {
    if (ApiHelper.isAuthExpired()){
      let refreshed = await ApiHelper.tryRefresh();
      if (!refreshed){
        this.props.history.push(urls.login);
        return;
      }
    }
    this.loadAppMenu()
    if (process.env.NODE_ENV === 'development'){
      return
    }
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault()
      e.returnValue = 'Do you really want to reload this page? You will lose any changes to the App Menu that haven\'t been saved.'
    })
  }

  //METHODS
  loadUpserveCategories = async () => {
    this.props.showLoading()
    let response = await ApiHelper.get(Endpoints.MenuCategories)
    this.props.closeLoading()
    if (!response.success){
      this.props.alert('error', `There was an error while connecting to Upserve. (${response.status})`)
      return
    }

    this.setState({upserveItems: response.data})
  }
  loadAppMenu = async () => {
    this.props.showLoading()
    let response = await ApiHelper.get(Endpoints.MenuCache)
    this.props.closeLoading()
    if (!response.success && response.status === 404){
      this.props.alert('error', `There was an error while retrieving the app menu. (${response.status})`)
      return
    }

    this.setState({appMenuSections: response.data.sections})
  }
  updateMenu = async () => {
    if (!window.confirm('Are you sure that you want to update the app menu as shown below? This action cannot be undone.')){
      return
    }
    this.props.showLoading()
    let response = await ApiHelper.post(Endpoints.Menu, this.state.appMenuSections)
    this.props.closeLoading()

    if (!response.success){
      this.props.alert('error', 'There was an issue while updating the app menu.')
      return
    }

    this.props.alert('success', 'The app menu has been updated!')
  }
  isBelowOpenSection = (index) => {
    if (!this.state.openAppSectionId || index === 0) {
      return false
    }
    return this.state.appMenuSections[index - 1].id === this.state.openAppSectionId
  }

  //EVENT HANDLERS
  handleUpdates = (name, value) => {
    this.setState((prevState) => {
      if (name.indexOf(':') > 0){
        let parts = name.split(':')
        let objName = parts[0]
        let keyName = parts[1]

        prevState[objName][keyName] = value
        return { [objName]: prevState[objName] }
      } else {
        prevState[name] = value
        return { prevState }
      }
    })
  }

  //MENU MANAGEMENT METHODS
  moveItem = (arr, from, to) => {
    let item = arr.splice(from, 1)[0]
    arr.splice(to, 0, item)
    return arr
  }
  moveSectionUp = (pos) => {
    if (pos === 0) {
      return
    }
    this.setState({appMenuSections: this.moveItem(this.state.appMenuSections, pos, pos - 1)})
  }
  moveSectionDown = (pos) => {
    if (pos === this.state.appMenuSections.length - 1) {
      return
    }
    this.setState({appMenuSections: this.moveItem(this.state.appMenuSections, pos, pos + 1)})
  }
  moveItemUp = (section, sectionIndex, itemPos) => {
    if (itemPos === 0) {
      return
    }

    section.items = this.moveItem(section.items, itemPos, itemPos - 1)
    let menu = this.state.appMenuSections.slice()
    menu[sectionIndex] = section

    this.setState({ appMenuSections: menu })
  }
  moveItemDown = (section, sectionIndex, itemPos) => {
    if (itemPos === section.items.length - 1) {
      return
    }
    
    section.items = this.moveItem(section.items, itemPos, itemPos + 1)
    let menu = this.state.appMenuSections.slice()
    menu[sectionIndex] = section

    this.setState({ appMenuSections: menu })
  }
  removeAppMenuItem = (menuItem, itemIndex) => {
    if (!window.confirm('Are you sure you want to delete this item from the app menu?')) {
      return
    }

    let appMenuSections = this.state.appMenuSections.slice()
    for(let section of appMenuSections) {
      if (section.id === this.state.openAppSectionId){
        section.items.splice(itemIndex, 1)
        break
      }
    }

    this.setState({ appMenuSections })
  }
  addItemToAppMenu = (item) => {
    if (!this.state.openAppSectionId) {
      this.props.alert('error', 'Please open an app section for this Upserve item.')
      return
    }

    let appMenuSections = this.state.appMenuSections.slice()
    for(let section of appMenuSections) {
      if (section.id === this.state.openAppSectionId){
        item.varietal = ''
        section.items.push(item)
        break;
      }
    }

    this.setState({ appMenuSections })
  }
  appSectionToggled = (section) => {
    this.setState({ openAppSectionId: this.state.openAppSectionId === section.id ? null : section.id })
  }
  upserveCategoryToggled = async (category, categoryIndex) => {
    if (category.id === this.state.openUpserveCategoryId){
      this.setState({ openUpserveCategoryId: null })
      return
    }
    if (category.items) {
      this.setState({ openUpserveCategoryId: category.id })
      return
    }

    this.props.showLoading()
    let response = await ApiHelper.get(Endpoints.MenuItems + '?categoryId=' + category.id)
    this.props.closeLoading()
    
    if (!response.data) {
      this.props.alert('error', 'Unable to load items for this category.')
      return
    }
    if (response.data.length < 1){
      this.props.alert('error', 'There are no items in this category.')
      return
    }

    let upserveItems = this.state.upserveItems.slice()
    upserveItems[categoryIndex].items = response.data

    this.setState({ 
      openUpserveCategoryId: this.state.openUpserveCategoryId === category.id ? null : category.id,
      upserveItems
    })
  }
  appItemClicked = (selectedItem) => {
    this.setState({ selectedItem })
  }
  itemSaved = (item) => {
    //Note
    this.setState({ selectedItem: null })
  }

  //RENDER
  render() {
    return (
      <div className='content-view' style={{marginBottom: '50px'}}>
        <div className='centered-container'>
          <div className='content-card singular-card'>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <p className='page-header' style={{flex: 2}}>App Menu</p>
              <BaseButton content='Publish Updated Menu' style={{flex: 1}} onClick={this.updateMenu}/>
            </div>
            <div>
              <p>Click an Upserve category to load menu items.</p>
              <p>Then click an item to add it to the currently open app menu category.</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20}}>
              <div style={{display: 'flex', flexDirection: 'column', flex: 1, marginRight: 20}}>
                <h3 style={{borderBottom: '1px solid black'}}>Upserve Categories</h3>
                {
                  this.state.upserveItems.length < 1 ? (
                    <button className='base-button' style={{marginTop: 20}} onClick={this.loadUpserveCategories}>Load Upserve Menu</button>
                  ) : (
                    this.state.upserveItems.map((category, categoryIndex) => (
                      <div key={'category-' + category.id} >
                        <div onClick={() => this.upserveCategoryToggled(category, categoryIndex)} className='menu-item'>
                          <p>{category.name}</p>
                        </div>
                        <Collapse isOpen={category.id === this.state.openUpserveCategoryId}>
                          {
                            category.items && category.items.map((item, idx) => (
                              <div key={'item-' + item.id}>
                                <div className='menu-item' style={{marginLeft: 20}}>
                                  <p>{item.name}</p>
                                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                    <ArrowButton disabled={!this.state.openAppSectionId} onClick={(e) => {e.stopPropagation(); this.addItemToAppMenu(item)}} />
                                  </div>
                                </div>
                              </div>
                            ))
                          }
                        </Collapse>
                      </div>
                    ))
                  )
                }
              </div>
              <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                <h3 style={{borderBottom: '1px solid black'}}>App Menu</h3>
                {
                  this.state.appMenuSections.length < 1 ? (
                    <button className='base-button' style={{marginTop: 20}} onClick={this.loadAppMenu}>Load App Menu</button>
                  ) : (
                    <>
                      {
                        this.state.appMenuSections.map((section, sectionIndex) => (
                          <div key={'section-' + section.id}>
                            <div onClick={() => this.appSectionToggled(section)} className='menu-item' style={this.state.openAppSectionId && sectionIndex > 0 && this.state.openAppSectionId === this.state.appMenuSections[sectionIndex - 1].id ? { borderTop: '1px solid black'} : null}>
                              <p>{section.name}</p>
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <span>
                                  <ArrowButton direction='up' style={{ marginRight: 5 }} disabled={sectionIndex === 0} onClick={(e) => {e.stopPropagation(); this.moveSectionUp(sectionIndex)}} />
                                  <ArrowButton direction='down' style={{ marginRight: 5 }} disabled={sectionIndex === this.state.appMenuSections.length - 1} onClick={(e) => {e.stopPropagation(); this.moveSectionDown(sectionIndex)}} />
                                </span>
                              </div>
                            </div>
                            <Collapse isOpen={section.id === this.state.openAppSectionId}>
                              {
                                section.items.map((item, idx) => (
                                  <div key={'item-' + item.id}>
                                    <div 
                                      className='menu-item' 
                                      style={{
                                        marginLeft: 20, 
                                        //show bottom border if not the last item for the section, unless this is also the last section
                                        borderBottomWidth: idx !== section.items.length - 1 || (idx === section.items.length - 1 && sectionIndex === this.state.appMenuSections.length - 1) ? 1 : 0
                                      }} 
                                      onClick={(e) => this.appItemClicked(item)}
                                    >
                                      <p>{item.name}</p>
                                      <p className='menu-varietal'>
                                        {`Varietal: ${item.varietal}`}
                                      </p>
                                      <p className='menu-varietal'>
                                        {`Tasting Notes: ${item.tastingNotesUrl && item.tastingNotesUrl !== '' ? 'Uploaded' : 'Not Uploaded'}`}
                                      </p>
                                      
                                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <span>
                                          <ArrowButton direction='up' disabled={idx === 0} onClick={(e) => {e.stopPropagation(); this.moveItemUp(section, sectionIndex, idx)}} />
                                          <ArrowButton direction='down' disabled={idx === section.items.length - 1} onClick={(e) => {e.stopPropagation(); this.moveItemDown(section, sectionIndex, idx)}} />
                                        </span>

                                        <CloseButton onClick={(e) => {e.stopPropagation(); this.removeAppMenuItem(item, idx)}} />
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                            </Collapse>
                          </div>
                        ))
                      }
                      {/* TODO Implement the add category button */}
                      {/* <button class='base-button' onClick={this.addCategory}>Add Category</button> */}
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <MenuItemModal
          item={this.state.selectedItem}
          save={this.itemSaved}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
      user: state.user.session,
    }
  }
const mapDispatchToProps = (dispatch) => {
  return {
    alert: (type, message) => dispatch(actions.alert(type, message)),
    setUserSession: (obj) => dispatch(actions.setUserSession(obj)),
    logout: () => dispatch(actions.logout()),
    showLoading: () => dispatch(actions.showLoading()),
    closeLoading: () => dispatch(actions.closeLoading())
  }
}

Page.propTypes = { 
  user: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Page)